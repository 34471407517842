import { showOffscreenMessage, hideOffscreenMessage } from '../offscreen-message/offscreen-message'

let timeout

export function renderToastMessage(message) {
  const toastMessageElement = document.querySelector('[data-toast-message]')
  const toastMessageBodyElement = toastMessageElement.querySelector('[data-toast-message-body]')
  clearTimeout(timeout)

  toastMessageBodyElement.innerHTML = message
  showOffscreenMessage(toastMessageElement)
  timeout = setTimeout(() => {
    hideOffscreenMessage(toastMessageElement)
  }, 3000)
}
