/**
 * @fileoverview dragscroll - scroll area by dragging
 * @version 0.0.8
 *
 * @license MIT, see http://github.com/asvd/dragscroll
 * @copyright 2015 asvd <heliosframework@gmail.com>
 */

function initSlider() {
  var _window = window
  var _document = document
  var mousemove = 'mousemove'
  var mouseup = 'mouseup'
  var mousedown = 'mousedown'
  var EventListener = 'EventListener'
  var addEventListener = 'add' + EventListener
  var removeEventListener = 'remove' + EventListener
  var newScrollX, newScrollY
  var dragStart, dragDuration, dragDistanceX

  /**
   * extends dragscroll with clickable anchors
   * compares click duration and the dragged distance
   */
  const anchors = [].slice.call(_document.querySelectorAll('[data-dragscroll-anchor]'))
  anchors.forEach(anchor => {
    anchor.addEventListener('click', (e) => {
      if (dragDuration > 140 && dragDistanceX > 4) e.preventDefault()
    })
  })

  var dragged = []
  var reset = function (i, el) {
    dragStart = 0
    dragDuration = 0
    dragDistanceX = 0
    for (i = 0; i < dragged.length;) {
      el = dragged[i++]
      el = el.container || el
      el[removeEventListener](mousedown, el.md, 0)
      _window[removeEventListener](mouseup, el.mu, 0)
      _window[removeEventListener](mousemove, el.mm, 0)
    }

    // cloning into array since HTMLCollection is updated dynamically
    dragged = [].slice.call(_document.querySelectorAll('[data-dragscroll]'))

    for (i = 0; i < dragged.length;) {
      (function (el, lastClientX, lastClientY, pushed, scroller, cont) {
        (cont = el.container || el)[addEventListener](
          mousedown,
          cont.md = function (e) {
            if (!el.hasAttribute('nochilddrag') ||
              _document.elementFromPoint(
                e.pageX, e.pageY
              ) == cont
            ) {
              pushed = 1
              lastClientX = e.clientX
              lastClientY = e.clientY
              dragStart = Date.now()
              dragDistanceX = e.clientX
              e.preventDefault()
            }
          }, 0
        )

        _window[addEventListener](
          mouseup, cont.mu = function (e) {
            pushed = 0
            dragDuration = Date.now() - dragStart
            dragDistanceX = Math.abs(e.clientX - dragDistanceX)
          }, 0
        )

        _window[addEventListener](
          mousemove,
          cont.mm = function (e) {
            if (pushed) {
              (scroller = el.scroller || el).scrollLeft -=
                newScrollX = (- lastClientX + (lastClientX = e.clientX))
              scroller.scrollTop -=
                newScrollY = (- lastClientY + (lastClientY = e.clientY))
              if (el == _document.body) {
                (scroller = _document.documentElement).scrollLeft -= newScrollX
                scroller.scrollTop -= newScrollY
              }
            }
          }, 0
        )
      })(dragged[i++])
    }
  }


  if (_document.readyState == 'complete') {
    reset()
  } else {
    _window[addEventListener]('load', reset, 0)
  }
}

export default initSlider
