import { trackEvent } from '../analytics/analytics'

function initSocialShare() {
  const socialShareComponents = document.querySelectorAll('[data-native-share]')

  if (navigator.share && socialShareComponents.length) {
    Array.prototype.forEach.call(socialShareComponents, (socialShare) => {
      const title = socialShare.getAttribute('data-native-share-text')
      const text = socialShare.getAttribute('data-native-share-text')
      const url = socialShare.getAttribute('data-native-share-url')
      const nativeShareButtonText = socialShare.getAttribute('data-native-share-button-text')
      const container = socialShare.querySelector('[data-native-share-container]')
      const item = document.createElement('li')

      item.innerHTML = createShareButton(nativeShareButtonText)
      container.appendChild(item)

      socialShare.querySelector('[data-native-share-button]').addEventListener('click', () => {
        navigator.share({
          title,
          text,
          url,
        })
          .then(() => trackEvent('share', 'native', url))
      })
    })
  }
}

function createShareButton(buttonText) {
  return `
      <button type="button" class="button button--icon" data-native-share-button aria-label="${buttonText}">
        <svg class="svg-icon">
          <use xlink:href="#share"></use>
        </svg>
      </button>
    `
}

export default initSocialShare
