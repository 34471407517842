function initNewsFeed() {
  const container = document.querySelector('[news-feed-nav]')

  if(!container) return

  const items = [...container.querySelectorAll('[news-feed-nav-item]')]
  const activeItem = container.querySelector('[news-feed-nav-active]')

  if(!items || !activeItem) return
  /** width must be set in css */
  const width = activeItem.clientWidth
  const index = items.indexOf(activeItem)
  const offset = width * .75

  /**
   * browsers have inconsistent scrollLeft values in rtl mode
   * chrome: '0, 1, 2, ...'
   * firefox, safari: '0, -1, -2, ...'
   */
  container.scrollLeft = -1

  if(container.scrollLeft < 0) {
    /** FireFox, Safari */
    container.scrollLeft = -(width * index - offset)
  } else {
    /** Chrome */
    container.scrollLeft = width * (items.length - index) - window.innerWidth + offset
  }

}

export default initNewsFeed
