const dateFormatter = Intl.DateTimeFormat('fa-IR', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
})

const timeFormatter = Intl.DateTimeFormat('fa-IR', {
  hour: 'numeric',
  minute: 'numeric'
})

const dateTimeFormatter = Intl.DateTimeFormat('fa-IR', {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
})

const slugFormatter = Intl.DateTimeFormat('nl-NL', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})

function today() {
  return Date.now()
}

function slugifyDate(date) {
  return isNaN(date) ? date : slugFormatter.format(date)
  //  .replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728))
  //  .split('/')
  //  .join('-')
}

function toFarsiDate(date) {
  return isNaN(date) ? date : dateFormatter.format(date)
}

function toFarsiTime(date) {
  return isNaN(date) ? date : timeFormatter.format(date)
}

function toFarsiDateTime(date) {
  return isNaN(date) ? date : dateTimeFormatter.format(date)
}

function toISOString(date) {
  if (isNaN(date)) {
    return NaN
  } else if (typeof date.toISOString === 'function') {
    return date.toISOString()
  }
  const dt = new Date(parseInt(date))
  return dt.toISOString()
}

module.exports = {
  today,
  slugifyDate,
  toISOString,
  toFarsiDate,
  toFarsiTime,
  toFarsiDateTime,
}
