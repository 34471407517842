import { trackEvent } from '../analytics/analytics'

function initInstallPrompt() {
  let deferredPrompt
  const installPrompts = document.querySelectorAll('[data-install-prompt')

  window.addEventListener('beforeinstallprompt', e => {
    e.preventDefault()
    deferredPrompt = e

    Array.prototype.forEach.call(installPrompts, prompt => {
      const trigger = prompt.querySelector('[data-install-prompt-trigger]')
      prompt.classList.add('is-active')

      trigger.addEventListener('click', showInstallPrompt)

      function showInstallPrompt() {
        deferredPrompt.prompt()
        deferredPrompt.userChoice.then(choiceResult => {
          if (choiceResult.outcome === 'accepted') {
            trackEvent('install-app', 'accepted')
          }
        }).finally(() => {
          trigger.removeEventListener('click', showInstallPrompt)
        })
      }
    })
  })
}

export default initInstallPrompt
