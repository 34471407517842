const getFocusTrap = () => import(/* webpackChunkName: "focus-trap" */'focus-trap')
import { emitEvent } from '../event-emitter/event-emitter'

let trap

async function enableFocusTrap(element) {
  disableFocusTrap()

  const { createFocusTrap } = await getFocusTrap()
  trap = createFocusTrap(element, {
    onDeactivate: () => emitEvent({ element, name: 'focus-trap-closed' }),
    clickOutsideDeactivates: true
  })

  trap.activate()
}

function disableFocusTrap() {
  if (trap) {
    trap.deactivate()
  }
}

export { enableFocusTrap, disableFocusTrap }
