import cookie from '../../../../lib/cookie'
import { priorities } from '../notification-center/notification-center'

const SURVEY_DATA_COOKIE_NAME = 'survey-data'
const surveyBannerTemplate = document.querySelector('[data-survey-banner-template]')

async function getSurveyNotification() {
  if(!surveyBannerTemplate) return

  try {
    // try to use "cached" survey data, otherwise fetch fresh data
    let surveyData = cookie(SURVEY_DATA_COOKIE_NAME) && JSON.parse(cookie(SURVEY_DATA_COOKIE_NAME))
    if (!surveyData) {
      surveyData = await fetch('/api/survey.json')
        .then(res => res.json())
        .then(data => {
          cookie(SURVEY_DATA_COOKIE_NAME, JSON.stringify(data), .125) // 3 hours
          return data
        })
    }

    if (surveyData.active) {
      const surveyDismissedCookieName = `cookie-survey-seen-${surveyData.slug}`
      if (cookie(surveyDismissedCookieName)) { // if survey was already acted on or dismissed
        return
      }

      const notificationBody = renderSurveyBanner(surveyData)

      return {
        priority: priorities.high,
        body: notificationBody,
        onClose: () => {
          if(!cookie(surveyDismissedCookieName)) {
            cookie(surveyDismissedCookieName, true, 0.0417)
          }
        },
        onRendered: () => {
          document.querySelector('[data-survey-seen-button]').addEventListener('click', () => {
            cookie(surveyDismissedCookieName, true, 365)
          })
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('Unable to fetch survey data', e)
  }
}

function renderSurveyBanner(data) {
  const clone = surveyBannerTemplate.cloneNode(true)
  if (!data.image) {
    clone.content.querySelector('[data-survey-banner-image]').style.display = 'none'
  }
  const renderedHtml = document.createElement('template')

  renderedHtml.innerHTML = clone.innerHTML
    .replace(/__SURVEY_BANNER_TYPE__/g, data.type)
    .replace(/__SURVEY_BANNER_TEXT__/g, data.text)
    .replace(/__SURVEY_BANNER_LINK__/g, data.link)
    .replace(/__SURVEY_BANNER_CTA__/g, data.cta)
    .replace(/\/__SURVEY_BANNER_SMALL_IMAGE_SRC__/g, data.image && data.image.small.src)
    .replace(/__SURVEY_BANNER_SMALL_IMAGE_WIDTH__/g, data.image && data.image.small.width)
    .replace(/__SURVEY_BANNER_SMALL_IMAGE_HEIGHT__/g, data.image && data.image.small.height)
    .replace(/\/__SURVEY_BANNER_MEDIUM_IMAGE_SRC__/g, data.image && data.image.medium.src)
    .replace(/__SURVEY_BANNER_MEDIUM_IMAGE_WIDTH__/g, data.image && data.image.medium.width)
    .replace(/__SURVEY_BANNER_MEDIUM_IMAGE_HEIGHT__/g, data.image && data.image.medium.height)
    .replace(/\/?__SURVEY_BANNER_LARGE_IMAGE_SRC__/g, data.image && data.image.large.src)
    .replace(/__SURVEY_BANNER_LARGE_IMAGE_WIDTH__/g, data.image && data.image.large.width)
    .replace(/__SURVEY_BANNER_LARGE_IMAGE_HEIGHT__/g, data.image && data.image.large.height)
    .replace(/__SURVEY_BANNER_IMAGE_ALT__/g, data.image && data.image.alt || '')
    .replace(/__SURVEY_BANNER_ASPECT_RATIO__/g, data.image && 100 * (data.image.large.height / data.image.large.width ) || '')

  return renderedHtml.content
}

export default getSurveyNotification
