const toggles = document.querySelectorAll('[data-toggle]')

function initToggle() {
  if(!toggles.length) {
    return
  }

  Array.prototype.forEach.call(toggles, toggle => {
    const targetId = toggle.getAttribute('data-toggle-id')
    const target = document.getElementById(targetId)
    // if there are multiple toggles that target the same element, we need to keep them in sync
    const relatedToggles = document.querySelectorAll(`[data-toggle-id="${targetId}"]`)

    toggle.setAttribute('aria-expanded', 'false')
    toggle.setAttribute('aria-controls', toggle.getAttribute('data-toggle-content-id'))
    toggle.addEventListener('click', event => handleClick(event, target, relatedToggles))
  })

  function handleClick(event, target, relatedToggles) {
    event.preventDefault()
    history.replaceState('', document.title, window.location.pathname + window.location.search) // reset hash if for some reason it ended up in the url
    target.classList.toggle('toggle--active')

    Array.prototype.forEach.call(relatedToggles, toggle => {
      toggle.setAttribute('aria-expanded', target.classList.contains('toggle--active'))
    })
  }
}

export default initToggle
