module.exports = {
  saveInitialStateInStorage,
  updateStorageWithItem,
  getItemsFromStorage
}

const LOCAL_STORAGE_KEY = 'censorship-circumvention-checklist-items'

function saveInitialStateInStorage(checkboxes) {
  let items = getItemsFromStorage()

  const upToDateEntries = Array.prototype.map.call(checkboxes, checkbox => checkbox.id)
  const savedEntries = Object.keys(items)
  const outdatedEntries = savedEntries.filter(entry => !upToDateEntries.includes(entry))
  const newEntries = upToDateEntries.filter(entry => !savedEntries.includes(entry))

  outdatedEntries.forEach(entry => delete items[entry])
  newEntries.forEach(entry => items[entry] = false)

  if (outdatedEntries.length || newEntries.length) {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(items))
  }
}

function updateStorageWithItem({itemKey, itemCompleted}) {
  let items = getItemsFromStorage()
  items[itemKey] = itemCompleted
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(items))
}

function getItemsFromStorage() {
  return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
}
