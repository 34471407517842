import { emitEvent } from '../event-emitter/event-emitter'
const forms = document.querySelectorAll('[data-form]')

function initForm() {
  const isSupported = typeof window.URL === 'function'
  if (!forms.length || !isSupported) {
    return
  }

  Array.prototype.forEach.call(forms, enhanceForm)
}

export function enhanceForm(form, fetchOptionsTransformer) {
  form.classList.add('is-enhanced')
  const submitButton = form.querySelector('[data-form-submit-button]')
  const formFeedbackElement = form.querySelector('[data-form-feedback]')
  const formFeedbackSuccessMessage = form.getAttribute('data-form-success-message')
  const formFeedbackErrorMessage = form.getAttribute('data-form-error-message')
  const endpoint = form.getAttribute('data-form-endpoint')

  form.addEventListener('submit', onSubmit)

  function onSubmit(e) {
    e.preventDefault()
    const isValid = e.target.checkValidity()
    formFeedbackElement.setAttribute('data-form-feedback', '')
    formFeedbackElement.textContent = ''

    if (isValid) {
      submitButton.setAttribute('disabled', 'disabled')

      const formData = new FormData(e.target)
      let postBody = {}

      formData.forEach((value, key) => {
        postBody[key] = value
      })

      const fetchOptions = fetchOptionsTransformer ? fetchOptionsTransformer(postBody) : {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(postBody)
      }

      fetch(endpoint, fetchOptions)
        .then((res) => {
          if (res.ok) {
            form.classList.remove('form--is-validated')
            form.reset()
            formFeedbackElement.setAttribute('data-form-feedback', 'success')
            formFeedbackElement.textContent = formFeedbackSuccessMessage
            emitEvent({ name: 'formSuccess' })
          } else {
            res.json().then(body => {
              formFeedbackElement.setAttribute('data-form-feedback', 'error')
              formFeedbackElement.textContent = body.message
            })
          }
          submitButton.removeAttribute('disabled')
        })
        .catch(() => {
          form.setAttribute('data-form-feedback', 'error')
          formFeedbackElement.textContent = formFeedbackErrorMessage
          submitButton.removeAttribute('disabled')
        })
    } else {
      form.classList.add('form--is-validated')
    }
  }
}

export default initForm
