import { priorities, addNotification } from '../notification-center/notification-center'

const template = document.querySelector('[data-censorship-checklist-notification]')
import { getItemsFromStorage } from '../../../pages/censorship-circumvention-checklist/storage-functions.js'

const FIRST_VISITS_LOCAL_STORAGE_KEY = 'first-visits'
const LAST_REMINDER_LOCAL_STORAGE_KEY = 'censorship-checklist-last-reminder'
const REMINDER_EXPIRATION_DAYS = 21 // to Test use -1 to show on every refresh
const REMINDER_AFTER_FIRST_VISITS = 4 // to test use 1

export default function maybeShowCensorshipChecklistNotification() {
  if(!template) {
    return
  }

  const container = document.querySelector('[data-checklist]')
  if(container) {
    return
  }

  if(isChecklistCompleted()) {
    return
  }

  if(getNumberOfFirstVisits() < REMINDER_AFTER_FIRST_VISITS) {
    trackFirstVisit()
    return
  }

  if(lastReminderWasLessThanThreeWeeksAgo()) {
    return
  }

  addNotification({
    priority: priorities.default,
    body: template.content.cloneNode(true),
    onClose: () => {
      localStorage.setItem(LAST_REMINDER_LOCAL_STORAGE_KEY, new Date())
    }
  })
}

function lastReminderWasLessThanThreeWeeksAgo() {
  const lastReminder = localStorage.getItem(LAST_REMINDER_LOCAL_STORAGE_KEY)

  if(lastReminder === null) {
    return false
  }

  const today = new Date()
  const threeWeeksAgo = new Date()
  threeWeeksAgo.setDate(today.getDate() - REMINDER_EXPIRATION_DAYS)

  return threeWeeksAgo < new Date(lastReminder)
}

function isChecklistCompleted() {
  const items = getItemsFromStorage()
  const checkedItems = Object.values(items).filter(Boolean).length
  return items.length === checkedItems
}

function getFirstVisits() {
  return JSON.parse(localStorage.getItem(FIRST_VISITS_LOCAL_STORAGE_KEY)) || {}
}

function getNumberOfFirstVisits() {
  return Object.keys(getFirstVisits()).length
}

function trackFirstVisit() {
  let firstVisits = getFirstVisits()

  let date = new Date().toDateString()

  firstVisits[date] = true
  localStorage.setItem(FIRST_VISITS_LOCAL_STORAGE_KEY, JSON.stringify(firstVisits))
}
