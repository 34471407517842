import { emitEvent } from '../event-emitter/event-emitter'
import cookie from '../../../../lib/cookie'
import { priorities } from '../notification-center/notification-center'

const template = document.querySelector('[data-cookie-notification-template]')
const COOKIE_NAME = 'cookie-consent-seen'

function getCookieNotification() {
  if(!template || cookie(COOKIE_NAME)) {
    return
  }

  return {
    priority: priorities.high,
    body: template.content.cloneNode(true),
    onClose: () => {
      cookie(COOKIE_NAME, true, 365)
      emitEvent({ name: 'cookie-consent-seen' })
    }
  }
}

export default getCookieNotification
