import { showOffscreenMessage, hideOffscreenMessage } from '../offscreen-message/offscreen-message'
import { enableInteractiveElements, disableInteractiveElements } from '../toggle-interactive-elements/toggle-interactive-elements'
import getCookieNotification from '../cookie-notification/cookie-notification'
import getSurveyNotification from '../survey-notification/survey-notification'

export const priorities = {
  high: 10,
  default: 5,
  low: 1
}
const notificationElement = document.querySelector('[data-notification]')
const notifications = []
let isActive = false

export async function initNotificationCenter() {
  if(!notificationElement) {
    return
  }

  const notificationsOnPageLoad = [
    getCookieNotification(),
    await getSurveyNotification(),
  ].filter(Boolean)

  notifications.push(...notificationsOnPageLoad)
  renderNotifications()
}

export function addNotification({ priority = 0, body, onClose = () => {} }) {
  notifications.push({ priority, body, onClose })
  renderNotifications()
}

function renderNotifications() {
  if(!notifications.length) {
    return
  }

  notifications.sort((a, b) => b.priority - a.priority)

  if(!isActive) {
    const { body, onClose, onRendered } = notifications[0]
    renderNotification({ body, onClose, onRendered })
    notifications.shift()
    isActive = true
  }
}

function renderNotification({ body, onClose = () => {}, onRendered = () => {}  } = {}) {
  const notificationCloseButton = notificationElement.querySelector('[data-notification-close]')
  const notificationBodyElement = notificationElement.querySelector('[data-notification-body]')

  notificationCloseButton.addEventListener('click', closeMessage)
  notificationBodyElement.innerHTML = ''
  notificationBodyElement.appendChild(body)
  enableInteractiveElements(notificationElement)
  onRendered()
  showOffscreenMessage(notificationElement)

  async function closeMessage(event) {
    event.preventDefault()
    notificationCloseButton.removeEventListener('click', closeMessage)
    disableInteractiveElements(notificationElement)
    onClose()
    isActive = false
    hideOffscreenMessage(notificationElement).then(renderNotifications)
  }
}
