function initInlineFootnote() {
  const footnotes = document.querySelectorAll('vo-footnote')

  if (!footnotes.length) {
    return
  }

  const BUTTON_CLASS = 'inline-footnote__toggle typo'
  const CONTENT_CLASS = 'inline-footnote__content'
  const TOGGLE_CLASS = 'toggle--active'

  Array.prototype.forEach.call(footnotes, (footnote, index) => {
    const id = `inline-footnote-${index}`

    const content = document.createElement('span')
    content.setAttribute('class', CONTENT_CLASS)
    content.setAttribute('aria-describedby', id)
    content.innerHTML = footnote.getAttribute('data-text')

    const button = document.createElement('button')
    button.setAttribute('id', id)
    button.setAttribute('type', 'button')
    button.setAttribute('class', BUTTON_CLASS)
    button.innerHTML = footnote.innerHTML

    button.addEventListener('click', event => {
      event.preventDefault()

      button.classList.toggle(TOGGLE_CLASS)
      content.classList.toggle(TOGGLE_CLASS)
    })

    footnote.parentNode.insertBefore(button, footnote)
    footnote.parentNode.insertBefore(content, footnote)

    footnote.parentNode.removeChild(footnote)
  })

}

export default initInlineFootnote
