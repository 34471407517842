function initPostParagraph() {
  const footnotes = document.querySelectorAll('[href*="_ftn"]:not([href*="_ftnref"])')
  const footnoteRefs = document.querySelectorAll('[href*="_ftnref"]')

  Array.prototype.forEach.call(footnotes, (footnote) => {
    const footnoteNumber = footnote.getAttribute('href').replace(/\D/g, '')
    footnote.setAttribute('aria-label', `به پانویس ${footnoteNumber} بروید`)
  })

  Array.prototype.forEach.call(footnoteRefs, (footnoteRef) => {
    const footnoteRefNumber = footnoteRef.getAttribute('href').replace(/\D/g, '')
    footnoteRef.setAttribute('aria-label', `${footnoteRefNumber} پرش به مرجع پانویس `)
  })
}

export default initPostParagraph
