async function initPrintBookmarks() {
  const printBookmarksButtons = document.querySelectorAll('[data-print-bookmarks]')

  if (printBookmarksButtons.length < 0 || !('serviceWorker' in navigator)) {
    return
  }

  const hasBookmarks = (await getBookmarks()).length
  if (hasBookmarks) {
    printBookmarksButtons.forEach(button => {
      button.classList.add('is-enhanced')
      button.addEventListener('click', printBookmarks)
    })
  }

  document.addEventListener('all-bookmarks-removed', removePrintButtons)
}

async function printBookmarks() {
  const bookmarkedPages = await getBookmarks()
    .then(async htmlRequests => {
      return await Promise.all(htmlRequests.map(getPostBody))
    })

  document.querySelector('[data-print-bookmarks-content]').innerHTML = `
    <ul>
      ${bookmarkedPages.map((page, index) => `
        <li>
          <a href="#post-${index + 1}">${index + 1} - ${page.title}</a>
        </li>
      `).join('')}
    </ul>
    ${bookmarkedPages.map((page, index) => `
      <div id="post-${index + 1}" class="post__page-break">
        <br/>
        <hr/>
        ${page.penName ? page.penName + ' - ' : ''}${page.date}
        <br/>
        <br/>
        ${page.body}
      </div>
    `).join('')}
  `

  document.querySelector('img').addEventListener('load', () => {
    window.print()
  })
}

function getBookmarks() {
  return caches.open('bookmarks')
    .then(cache => cache.keys())
}

function getPostBody(request) {
  return caches.match(request)
    .then(response => response ? response.text() : '')
    .then(htmlAsText => {
      const template = document.createElement('template')
      template.innerHTML = htmlAsText
      const pageMetaTemplate = template.content.querySelector('[data-print-bookmarks-entry]')
      return {
        penName: template.content.querySelector('[data-post-pen-name]')?.textContent,
        body: pageMetaTemplate.innerHTML,
        date: template.content.querySelector('[data-post-date]').textContent,
        title: template.content.querySelector('[data-post-title]').textContent,
      }
    })
}

function removePrintButtons() {
  document.querySelectorAll('[data-print-bookmarks]').forEach(button => button.style.display = 'none')
}

export default initPrintBookmarks
