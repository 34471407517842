import cookie from '../../../../lib/cookie'
const embeds = document.querySelectorAll('[data-embed-src]')

const initEmbedHandler = function () {
  const COOKIE_NAME = 'embed_accepted'
  const CLASSNAME = 'embed-accepted'
  const buttons = document.querySelectorAll('[data-embed-button]')

  if (cookie(COOKIE_NAME)) {
    setEnabledClass(true)
    enableEmbeds(true)
  }

  if (buttons.length) {
    Array.prototype.forEach.call(buttons, button => {
      button.addEventListener('click', () => setPreference(button.dataset.embedButton))
    })
  }

  /**
   *
   * @param {string} 'accept' || decline
   */
  function setPreference(preference) {
    const state = preference === 'accept'

    setCookie(state)
    setEnabledClass(state)
    enableEmbeds(state)
  }

  /**
   *
   * @param {boolean} set
   */
  function setCookie(set) {
    if (set) {
      cookie(COOKIE_NAME, true, 365)
    } else {
      cookie(COOKIE_NAME, false)
    }
  }

  /**
   *
   * @param {boolean} enabled
   */
  function setEnabledClass(enabled) {
    if (enabled) {
      document.documentElement.classList.add(CLASSNAME)
    } else {
      document.documentElement.classList.remove(CLASSNAME)
    }
  }

  /**
   *
   * @param {boolean} enabled
   */
  function enableEmbeds(enabled) {
    Array.prototype.forEach.call(embeds, embed => {
      if(embed.hasAttribute('data-embed-instagram')) {
        enableEmbedScript(embed, enabled, 'data-embed-instagram-script')
        return
      }

      if(embed.hasAttribute('data-embed-flourish')) {
        enableEmbedScript(embed, enabled, 'data-embed-flourish-script')
        return
      }

      embed.src = enabled ? embed.dataset.embedSrc : ''
    })
  }

  function enableEmbedScript(embed, enabled, scriptAttribute) {
    let script = document.querySelector(`[${scriptAttribute}]`)

    if (enabled && !script) {
      script = document.createElement('script')
      script.src = embed.getAttribute('data-embed-src')
      script.setAttribute(scriptAttribute, null)
      document.body.appendChild(script)
    } else if (!enabled && script) {
      script.remove()
    }
  }
}

export default initEmbedHandler
