import {trackEvent} from '../analytics/analytics'
import cookie from '../../../../lib/cookie'

export default function initAudiencePoll() {
  const audiencPolls = document.querySelectorAll('[data-audience-poll]')

  if (!audiencPolls.length) {
    return
  }

  Array.prototype.forEach.call(audiencPolls, (pollElement) => {
    createAudiencePoll(pollElement)
  })
}

function createAudiencePoll(pollElement) {
  const pollId = pollElement.getAttribute('data-audience-poll')
  const answeredPolls = (cookie('audience-poll') && JSON.parse(cookie('audience-poll'))) || []
  const pollAlreadyAnswered = answeredPolls.includes(pollId)

  if (pollAlreadyAnswered) {
    showThankYouMessage(pollElement)
  } else {
    pollElement.addEventListener('submit', (e) => {
      e.preventDefault()
      const answerId = e.target.querySelector('input:checked').value
      trackEvent('polls', `${pollId}: ${answerId}`, document.title)

      showThankYouMessage(pollElement)

      // save answered polls into cookie in order to not show again
      const answeredPolls = cookie('audience-poll') ? JSON.parse(cookie('audience-poll')) : []
      answeredPolls.push(pollId)
      cookie('audience-poll', JSON.stringify(answeredPolls), 1000)
    })
  }
}

function showThankYouMessage(pollElement) {
  const answers = pollElement.querySelector('[data-audience-poll-answers]')
  const feedbackMessage = pollElement.querySelector('[data-audience-poll-feedback-message]')
  answers.classList.remove('is-visible')
  feedbackMessage.classList.add('is-visible')
}
