/**
 * Function returns a timezone aware datetime object given either a post or a comment.
 * - assumes a comment if `comment_date_gmt` is available in the object
 * - (uses `comment_date_gmt` if possible for comments, fallback to `comment_date`)
 * - uses `post_date_gmt` if possible for posts, fallback to `post_date`
 * - interprets non-gmt datetimes as in the Tehran timezone (UTC+03:30)
 * - if all else fails, returns the beginning of the Unix epoch
 * Returns a timezone aware datetime (insofar you can speak of timezone aware with
 * these Javascript datetime objects
 */
module.exports = function normalizeWordpressTimestamp(object) {
  let [date, gmt] = [undefined, false]
  try {
    if ('comment_date_gmt' in object) { [date, gmt] = [object.comment_date_gmt, true] }
    else if ('post_date_gmt' in object) { [date, gmt] = [object.post_date_gmt, true] }
    else if ('date_gmt' in object) { [date, gmt] = [object.date_gmt, true] }
    else if ('comment_date' in object) { [date, gmt] = [object.comment_date, false] }
    else if ('post_date' in object) { [date, gmt] = [object.post_date, false] }
    else if ('date' in object) { [date, gmt]= [object.date, false] }
  } catch (e) {
    if (e instanceof TypeError) {
      /* eslint-disable no-console */
      console.warn('warning: `timestamp/1` in timestamp.js called with non-object:')
      console.warn(object)
      /* eslint-enable no-console */
    } else {
      throw e
    }
  }

  const dt = new Date(date)
  const gmt_offset = 3.5 * 60 * 60 * 1000

  if ( isNaN(dt) ) {
    return date
  }

  return gmt ? new Date(dt.valueOf() + gmt_offset) : dt
}
