module.exports = {
  updateTemplate
}

function updateTemplate({ items }) {
  const checkedItems = Object.values(items).filter(Boolean).length
  const totalItems = Object.keys(items).length

  updateTemplateProgressBar({ checkedItems, totalItems })
  updateTemplateMessage({ checkedItems, totalItems })
  updateTemplateCheckboxes({ items })
}

function updateTemplateProgressBar({ checkedItems, totalItems }) {
  const percentageDone = checkedItems > 0 ? Math.round((checkedItems / totalItems) * 100) : 0

  const progressBar = document.querySelector('[data-checklist-progress-bar]')
  progressBar.value = checkedItems
  progressBar.innerText = percentageDone + '%'
}

function updateTemplateMessage({ checkedItems, totalItems }) {
  let messageToShow

  if(checkedItems === 0) {
    messageToShow = 'checklistMessageStart'
  }

  if(checkedItems > 0 && checkedItems < totalItems) {
    messageToShow = 'checklistMessageInProgress'
  }

  if(checkedItems === totalItems) {
    messageToShow = 'checklistMessageFinished'
  }

  document.querySelector('[data-checklist-message-container]').innerText = document.querySelector('[data-checklist]').dataset[messageToShow]
}

function updateTemplateCheckboxes({ items }) {
  Object.keys(items).forEach(itemKey => {

    const checkbox = document.getElementById(itemKey)

    if(typeof checkbox === 'undefined') {
      return
    }

    checkbox.checked = items[itemKey]

  })
}
