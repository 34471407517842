import {
  getMirrorDb,
  MIRROR_DB_STORE,
  MIRROR_PRIMARY,
  MIRROR_LOWEST_PRIORITY,
  MIRROR_UP,
  MIRROR_ACTIVE,
} from '../idb-storage/idb-storage'

const initMirrorStatus = async () => {
  const components = document.querySelectorAll('[data-mirror-status]')
  const offlineNotifications = document.querySelectorAll('[data-mirror-status-offline-notification]')

  if (!('serviceWorker' in navigator) || !components.length) {
    return
  }

  const db = await getMirrorDb()

  Array.prototype.forEach.call(components, component => {
    component.classList.add('mirror-status--enhanced')
  })

  setOriginStatus()

  navigator.serviceWorker.addEventListener('message', async (event) => {
    const payload = JSON.parse(event.data)
    if (payload.type === 'mirror-status-updated') {
      setOriginStatus()
    }
  })

  async function setOriginStatus() {
    const tx = db.transaction(MIRROR_DB_STORE)
    const index = tx.store.index('nextAvailable')

    const count = await index.count()
    if (count < 1) {
      return
    }

    const primaryIsUp = (await index.count(IDBKeyRange.bound([MIRROR_UP, MIRROR_PRIMARY], [MIRROR_UP, MIRROR_PRIMARY]))) > 0
    const anyAreUp = (await index.count(IDBKeyRange.bound([MIRROR_UP, MIRROR_PRIMARY], [MIRROR_UP, MIRROR_LOWEST_PRIORITY]))) > 0
    const allAreDown = (await index.count(IDBKeyRange.bound([MIRROR_UP, MIRROR_PRIMARY], [MIRROR_ACTIVE, MIRROR_LOWEST_PRIORITY]))) === 0

    if (primaryIsUp) {
      return updateOriginStatus('up')
    } else if (anyAreUp) {
      return updateOriginStatus('low')
    } else if (allAreDown) {
      Array.prototype.forEach.call(offlineNotifications, offlineNotification => {
        offlineNotification.classList.add('offline-message--is-active')
        offlineNotification.setAttribute('role', 'alert')
        offlineNotification.setAttribute('aria-live', 'assertive')
      })
      return updateOriginStatus('down')
    }
  }

  function updateOriginStatus(status) {
    Array.prototype.forEach.call(components, component => {
      component.setAttribute('data-mirror-status', status)
    })
  }
}

export default initMirrorStatus
