const mailtoForms = document.querySelectorAll('[data-mailto-form]')
const enableClass = 'mailto-form--enhanced'

function initMailtoForm() {
  if(!mailtoForms.length || !Array.from) {
    return
  }

  Array.prototype.forEach.call(mailtoForms, node => {
    node.classList.add(enableClass)
    const form = node.querySelector('[data-mailto-form-enhanced]')
    form.addEventListener('submit', event => submitHandler(event, form))
  })
}

function submitHandler(event, form) {
  event.preventDefault()

  const formData = new FormData(form)
  const output = Array.from(formData.entries()).map( subArray => `${subArray[0]}=${encodeURIComponent(subArray[1])}` ).join('&')
  window.open(`${form.action}?${output}`)
}

export default initMailtoForm
