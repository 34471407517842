const INTERACTIVE_ELEMENTS = 'a, button, input, select, textarea, details, audio, video, object, [tabindex]:not([tabindex="-1"])'

export function enableInteractiveElements(element) {
  Array.prototype.forEach.call(element.querySelectorAll(INTERACTIVE_ELEMENTS), element => {
    element.removeAttribute('tabindex')
  })
}

export function disableInteractiveElements(element) {
  Array.prototype.forEach.call(element.querySelectorAll(INTERACTIVE_ELEMENTS), element => {
    element.setAttribute('tabindex', '-1')
  })
}
