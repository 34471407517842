import { trackEvent } from '../analytics/analytics'

function initNewsLetterSubscribe() {
  const forms = document.querySelectorAll('[data-newsletter-subscribe]')
  if (!forms.length || !window.fetch) {
    return
  }

  Array.prototype.forEach.call(forms, (form) => {
    form.addEventListener('submit', onSubmit)
    const feedbackElement = form.querySelector('[data-form-feedback]')
    const pendingMessage = form.getAttribute('data-newsletter-subscribe-verify-message')
    const successMessage = form.getAttribute('data-newsletter-subscribe-success-message')
    const errorMessage = form.getAttribute('data-newsletter-subscribe-error-message')

    function onSubmit(e) {
      e.preventDefault()
      feedbackElement.innerText = ''
      const body = new URLSearchParams(new FormData(form)).toString()
      fetch(`${process.env.FUNCTIONS_BASE_URL}/.netlify/functions/newsletter-subscribe`, {
        method: 'POST',
        body
      })
        .then(res => res.json())
        .then(body => {
          renderFeedbackMessage(body.status === 'pending' ? pendingMessage : successMessage, 'success')
          trackEvent('newsletter', 'subscribe')
        })
        .catch(() => renderFeedbackMessage(errorMessage, 'error'))
    }

    function renderFeedbackMessage(message, type) {
      feedbackElement.setAttribute('data-form-feedback', type)
      feedbackElement.innerText = message
    }
  })
}

export default initNewsLetterSubscribe
