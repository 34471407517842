function initShareInstructions() {
  const PANEL_ACTIVE_CLASS = 'panel--active'
  const TAB_ACTIVE_CLASS = 'tab--active'

  const tabs = Array.from(document.querySelectorAll('[data-share-instruction-tab]'))

  if ( !tabs || !tabs.length ) {
    return
  }
  
  let tabpanels = []
  let firstTab = null
  let lastTab = null

  for (let i = 0; i < tabs.length; i++) {
    let tab = tabs[i]
    let tabpanel = document.getElementById(tab.getAttribute('aria-controls'))

    tab.tabIndex = -1
    tab.setAttribute('aria-selected', 'false')
    tabpanels.push(tabpanel)

    tab.addEventListener('keydown', event => handleOnKeyDown(event))
    tab.addEventListener('click', event => handleOnClick(event))

    if (!firstTab) {
      firstTab = tab
    }
    lastTab = tab
  }
  setSelectedTab(firstTab, false)
  
  function setSelectedTab(currentTab, setFocus = true) {
    for (let i = 0; i < tabs.length; i++) {
      let tab = tabs[i]
      
      if (currentTab === tab) {
        tab.setAttribute('aria-selected', 'true')
        tab.tabIndex = 0
        tab.classList.add(TAB_ACTIVE_CLASS)
        tabpanels[i].classList.add(PANEL_ACTIVE_CLASS)
        if (setFocus) {
          tab.focus({ })
        }
      } else {
        tab.setAttribute('aria-selected', 'false')
        tab.tabIndex = -1
        tab.classList.remove(TAB_ACTIVE_CLASS)
        tabpanels[i].classList.remove(PANEL_ACTIVE_CLASS)
      }
    }
  }
  
  function selectPreviousTab(currentTab) {
    let index

    if (currentTab === lastTab) {
      setSelectedTab(firstTab)
    } else {
      index = tabs.indexOf(currentTab)
      setSelectedTab(tabs[index + 1])
    }
  }

  function selectNextTab(currentTab) {
    let index

    if (currentTab === firstTab) {
      setSelectedTab(lastTab)
    } else {
      index = tabs.indexOf(currentTab)
      setSelectedTab(tabs[index - 1])
    }
  }

  function handleOnKeyDown(event) {
    let target = event.currentTarget
    let flag = false

    switch (event.key) {
      case 'ArrowLeft':
        selectPreviousTab(target)
        flag = true
        break
      case 'ArrowRight':
        selectNextTab(target)
        flag = true
        break
      case 'Home':
        setSelectedTab(firstTab)
        flag = true
        break
      case 'End':
        setSelectedTab(lastTab)
        flag = true
        break
      default:
        break
    }

    if (flag) {
      event.preventDefault()
    }
  }
  
  function handleOnClick(event) {
    setSelectedTab(event.currentTarget)
  }
}

export default initShareInstructions
