import cookie from '../../../../lib/cookie'
const buttons = document.querySelectorAll('[data-font-size]')

function initFontSizeMenu() {
  if(!buttons.length) {
    return
  }

  let size = null

  const COOKIE_NAME = 'font_size_'
  const CLASS_NAME = 'font-size-scale--'

  new Array('large', 'small').forEach( option => {
    if(cookie(`${COOKIE_NAME}${option}`)) {
      setFontSize(option)
    }
  })

  Array.prototype.forEach.call(buttons, button => {
    button.addEventListener('click', () => {
      setFontSize(button.dataset.fontSize)
    })
  })

  function setFontSize(newSize) {
    if(size) {
      cookie(`${COOKIE_NAME}${size}`, false)
      document.documentElement.classList.remove(`${CLASS_NAME}${size}`)
    }

    if(size === newSize) {
      size = null
    } else {
      size = newSize
      cookie(`${COOKIE_NAME}${newSize}`, true, 365)
      document.documentElement.classList.add(`${CLASS_NAME}${newSize}`)
    }
  }

}

export default initFontSizeMenu
