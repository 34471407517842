import { updateStorageWithItem, saveInitialStateInStorage, getItemsFromStorage } from './storage-functions.js'
import { updateTemplate } from './template-functions.js'
import maybeShowCensorshipChecklistNotification from '../../_includes/components/censorship-checklist-notification/censorship-checklist-notification.js'
import { trackEvent } from '../../_includes/components/analytics/analytics.js'

function initChecklist() {
  const container = document.querySelector('[data-checklist]')
  if(!container) {
    maybeShowCensorshipChecklistNotification()
    return
  }

  const checkboxes = container.querySelectorAll('[data-checklist-checkbox]')
  Array.prototype.forEach.call(checkboxes, element => element.addEventListener('click', checkboxHandler))

  saveInitialStateInStorage(checkboxes)

  updateTemplate({ items: getItemsFromStorage() })
}

function checkboxHandler(event) {
  updateStorageWithItem({ itemKey: event.target.id, itemCompleted: event.target.checked })

  const items = getItemsFromStorage()
  updateTemplate({ items })

  trackEvent(
    'censorship-circumvention-checklist',
    event.target.checked ? 'check' : 'uncheck',
    event.target.id,
    items
  )
}

export default initChecklist
