// Create a render event
export function emitEvent({ detail, element, name }) {
  let event
  const el = element ? element : window
  if (typeof window.CustomEvent === 'function') {
    event = new CustomEvent(name, {
      bubbles: true,
      detail,
    })
  } else {
    event = document.createEvent('CustomEvent')
    event.initCustomEvent(name, true, false, detail)
  }

  el.dispatchEvent(event)
}
