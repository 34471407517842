const SESSION_STORAGE_KEY = 'radio-zamaneh-is-inside-iran'

function initHideForIran() {
  const isInsideIran = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_KEY)) ?? null

  if (isInsideIran == null) {
    fetch(process.env.FUNCTIONS_BASE_URL + '/.netlify/functions/is-inside-iran')
      .then(response => response.json())
      .then(isInsideIran => {
        sessionStorage.setItem(SESSION_STORAGE_KEY, isInsideIran)
        if (isInsideIran) {
          onInsideIran()
        }
      })
  } else {
    if (isInsideIran) {
      onInsideIran()
    }
  }
}

function onInsideIran() {
  document.querySelectorAll('[data-hide-for-iran]').forEach(element => element.style.display = 'none')
}

export default initHideForIran
