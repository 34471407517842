const ACTIVE_CLASS = 'offscreen-message--is-active'

export function showOffscreenMessage(element) {
  element.removeAttribute('aria-hidden')
  element.classList.add(ACTIVE_CLASS)
  element.setAttribute('role', 'alert')
  element.setAttribute('aria-live', 'assertive')
}

export function hideOffscreenMessage(element) {
  element.setAttribute('aria-hidden', 'true')
  element.classList.remove(ACTIVE_CLASS)
  element.removeAttribute('role')
  element.removeAttribute('aria-live')

  return new Promise((resolve) => element.ontransitionend = resolve)
}
