import qs from 'querystringify'

const searchResults = document.querySelector('[data-search-results]')
const searchResultsForm = document.querySelector('[data-search-results-form]')
const searchResultsInput = document.querySelector('[data-search-results-input]')
const nextButton = document.querySelector('[data-search-results-next-button]')
const pagination = document.querySelector('[data-search-results-pagination]')
const previousButton = document.querySelector('[data-search-results-previous-button]')
const paginationDivider = document.querySelector('[data-search-results-pagination-divider]')
const pagesElement = document.querySelector('[data-search-results-pages]')
const pageNumberElement = document.querySelector('[data-search-results-page-number]')
const pageTotalElement = document.querySelector('[data-search-results-page-total]')
const resultTemplate = document.querySelector('[data-search-results-item-template]')
const resultQueryElement = document.querySelector('[data-search-results-query]')
const resultCountElement = document.querySelector('[data-search-results-count]')
const searchResultsWrapperElement = document.querySelector('[data-search-results-wrapper]')
const PAGESIZE = 10

export default function initSearchResults() {
  if (!searchResults || !searchResultsForm) {
    return
  }
  const { q, page } = qs.parse(window.location.search)

  if (q) {
    fetchResults(q, parseInt(page) ? parseInt(page) : 1)
  }

  searchResultsForm.addEventListener('submit', (e) => {
    e.preventDefault()
    const query = e.target.elements.q.value
    fetchResults(query)
  })
}

function fetchResults (query, page = 1) {
  let hasError = false
  searchResultsInput.value = query
  searchResultsWrapperElement.classList.add('search-results--is-pending')

  return fetch(`https://www.googleapis.com/customsearch/v1/siterestrict?q=${query}&start=${(((page - 1) * PAGESIZE) + 1)}&key=${process.env.GOOGLE_SITE_SEARCH_KEY}&cx=98816df14a622beed&sort=date`)
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw Error(res)
      }

    })
    .then(data => renderResults(data, page))
    .catch( () => {
      hasError = true
      searchResultsWrapperElement.classList.add('search-results--has-error')
    })
    .finally(() => {
      searchResultsWrapperElement.classList.remove('search-results--is-pending')
      if(!hasError) {
        searchResultsWrapperElement.classList.remove('search-results--has-error')
        searchResultsWrapperElement.classList.add('search-results--has-results')
      }
      document.documentElement.scrollIntoView({ behavior: 'smooth' })
    })
}

function renderResults (data, page) {
  const totalResults = data.searchInformation.totalResults
  const query = data.queries.request[0].searchTerms
  const url = `${window.location.origin}${window.location.pathname}?${qs.stringify({
      q: query,
    ...(page && { page })
    })
  }`
  window.history.pushState({}, '', url)

  if (parseInt(totalResults)) {
    const results = data.items.map(item => {
      const snippet = item.snippet.replace(
        /(^[a-zA-Z].*?(?= \.{3}))/,
        '<span lang="en" dir="ltr">$1</span>'
      )

      return resultTemplate.innerHTML
      .replace('__TITLE__', item.title)
      .replace('__SNIPPET__', snippet)
      .replace('__LINK__', item.link)
    })
    searchResults.innerHTML = results.join('')

  } else {
    searchResults.innerHTML = ''
  }

  if(totalResults < 1) {
    searchResults.style.display = 'none'
    pagination.style.display = 'none'
    pagesElement.style.display = 'none'
  } else {
    searchResults.style.display = ''
    pagination.style.display = ''
    pagesElement.style.display = ''
  }

  resultCountElement.innerText = totalResults
  resultQueryElement.innerText = query
  pageNumberElement.innerText = page
  pageTotalElement.innerText = totalResults / PAGESIZE <= PAGESIZE - 1 ? Math.ceil(totalResults / PAGESIZE) : PAGESIZE

  if (data.queries.nextPage) {
    nextButton.style.display = ''
    nextButton.href = nextButton.getAttribute('data-search-results-next-button')
      .replace('__QUERY__', query )
      .replace('__PAGE__', page + 1)
  } else {
    nextButton.style.display = 'none'
  }

  if (data.queries.previousPage) {
    previousButton.style.display = ''
    paginationDivider.style.display = ''
    previousButton.href = previousButton.getAttribute('data-search-results-previous-button')
      .replace('__QUERY__', data.queries.request[0].searchTerms )
      .replace('__PAGE__', page - 1)
  } else {
    previousButton.style.display = 'none'
    paginationDivider.style.display = 'none'
  }

  if(
    (page) * PAGESIZE > totalResults // last page
    || page === 10 // google maximum
  ) {
    paginationDivider.style.display = 'none'
    nextButton.style.display = 'none'
  }
}
