function debounce(fn, ms) {
  let timer
  return function() {
    clearTimeout(timer)
    timer = setTimeout( function() {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  }
}

const setViewportHeight = debounce( function() {
  document.documentElement.style.setProperty('--vh', window.innerHeight + 'px')
}, 500)

window.addEventListener('resize', setViewportHeight)

export default setViewportHeight
