// import { getNormalizedPath } from '../../../../lib/get-normalized-path'
import { getAnalyticsDb } from '../idb-storage/idb-storage'

export default initAnalytics

export function trackEvent(eventName, action, name, value) {
  if (window._ain?.LOADED) {
    window._ain.pushConversion(eventName, {
      ...(action && { action }),
      ...(name && { name }),
      ...(value && { value }),
      postid: window._ain.postid,
    })
  } else {
    window._ainQueue ??= []
    window._ainQueue.push({ eventName, action, name, value })
  }
}

function initAnalytics() {
  if (process.env.ANALYTICS_ENABLED === 'false'
    || !window.pageTrackingData
  ) {
    return
  }

  initGlobalAnalytics()
  initComponentAnalytics()
}

async function initGlobalAnalytics() {
  const smartOcto = loadSmartOcto()

  if (!window._ain.LOADED) {
    smartOcto.onload = () => {
      while (window._ainQueue?.length) {
        const event = window._ainQueue.pop()
        trackEvent(...event)
      }
    }
  }

  if ('serviceWorker' in navigator) {
    const analyticsDb = await getAnalyticsDb()
    const transaction = analyticsDb.transaction('events', 'readwrite')
    const events = await transaction.store.getAll()

    events.forEach((event) => {
      trackEvent(event.category, event.action, event.name, event.value)
    })

    await analyticsDb.clear('events')
    await transaction.done
  }
}

function initComponentAnalytics() {
  const analyticsComponents = document.querySelectorAll('[data-analytics]')

  if (analyticsComponents.length < 1) {
    return
  }

  Array.prototype.forEach.call(analyticsComponents, (analyticsComponent) => {
    const eventName = analyticsComponent.getAttribute('data-analytics-event-name')
    const action = analyticsComponent.getAttribute('data-analytics-action')
    const name = analyticsComponent.getAttribute('data-analytics-name')
    const value = analyticsComponent.getAttribute('data-analytics-value')

    if (!eventName) {
      return
    }

    analyticsComponent.addEventListener('click', () => {
      trackEvent(eventName, action, name, value)
    })
  })
}

async function loadSmartOcto () {
  const analyticsData = window.pageTrackingData

  window._ain = {
    id: process.env.SMARTOCTO_DOMAIN_ID,
    access_level: 'free',
    reader_type: 'anonymous',
    social: {
      ln_count: '0',
      pn_count: '0'
    },
    ...analyticsData
  }

  /* We're not for now tracking the mirror location as part of the URL */
  // if ('serviceWorker' in navigator) {
  //   const path = getNormalizedPath(new Request(window.location))
  //   const res = await caches.match(path)
  //   const mirror = res ? await res.headers.get('x-mirror') : window.location.origin
  //   window._ain['url'] = mirror
  // }

  const sf = document.createElement('script')
  sf.type='text/javascript'
  sf.async=true
  sf.src='https://d7d3cf2e81d293050033-3dfc0615b0fd7b49143049256703bfce.ssl.cf1.rackcdn.com/stf.js'
  const t = document.getElementsByTagName('script')[0]
  t.parentNode.insertBefore(sf, t)
  return sf
}
