const getPusher = () => import(/* webpackChunkName: "pusher" */'@pusher/push-notifications-web')
import { renderToastMessage } from '../toast-message/toast-message'

const pushSubscribeButtons = document.querySelectorAll('[data-push-subscribe]')
let beamsClient
let beamsDeviceId
let errorMessage

export default async function initPushSubscribe () {
  if (
    !('serviceWorker' in navigator) ||
    !('PushManager' in window) ||
    !pushSubscribeButtons.length
  ) {
    // push notification not supported or not relevant at this page
    return
  }

  const { Client } = await getPusher()
  beamsClient = new Client({
    instanceId: '99a16e20-9bad-4a36-93d1-16a52d1a5510',
  })
  beamsDeviceId = await beamsClient.getDeviceId()
  errorMessage = pushSubscribeButtons[0].getAttribute('data-push-subscribe-error-message')
  setPermissionUi()
  pushSubscribeButtons.forEach(async button => {
    button.classList.add('enhanced')
    button.addEventListener('click', () => setPermission(button.getAttribute('data-push-subscribe-interest')))
  })
}

async function setPermission(interest) {
  if (beamsDeviceId) {
    beamsClient
      .stop()
      .then(() => {
        beamsDeviceId = null
        return setPermissionUi()
      })
      .catch(console.error)
  } else {
    beamsClient
      .start()
      .then((beamsClient) => beamsClient.getDeviceId())
      .then((deviceId) => {
          beamsDeviceId = deviceId
          return setPermissionUi()
        })
      .then(() => beamsClient.addDeviceInterest(interest))
      .then(() => beamsClient.getDeviceInterests())
      .catch((err => {
        console.error(err)
        renderToastMessage(errorMessage)
      }))
  }
}

async function setPermissionUi () {
  const permissionStatus = await navigator.permissions.query({name: 'notifications'})

  pushSubscribeButtons.forEach(async button => {
    const activateText = button.getAttribute('data-push-subscribe-activate-text')
    const deactivateText = button.getAttribute('data-push-subscribe-deactivate-text')
    const deniedText = button.getAttribute('data-push-subscribe-denied-text')

    if (permissionStatus.state === 'denied') {
      button.innerText = deniedText
    } else if (beamsDeviceId) {
      button.innerText = deactivateText
    } else {
      button.innerText = activateText
    }
  })
}
