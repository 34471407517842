function initOpinionPanelForm() {
  const forms = document.querySelectorAll('[data-opinion-panel-form]')

  if(!forms.length) {
    return
  }

  Array.prototype.forEach.call(forms, form => {
    form.addEventListener('submit', onSubmit)
    const templateLink = form.getAttribute('action')

    function onSubmit(event) {
      event.preventDefault()

      const isValid = event.target.checkValidity()

      if(isValid) {
        const email = encodeURIComponent(new FormData(event.target).get('EMAIL'))
        const url = templateLink.replace('{email}', email)
        window.open(url, '_blank').focus()
      }
    }
  })
}

export default initOpinionPanelForm
