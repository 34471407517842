import { showOffscreenMessage, hideOffscreenMessage } from '../offscreen-message/offscreen-message'
const staleContentNotificationElement = document.querySelector('[data-stale-content-notification]')

let timeout

function initStaleContentNotification() {
  if (!('serviceWorker' in navigator) || !staleContentNotificationElement) {
    return
  }

  const reloadButton = document.querySelector('[data-stale-content-notification-reload-button]')
  const shouldManualRefresh = staleContentNotificationElement.hasAttribute('data-stale-content-notification-manual-refresh')

  navigator.serviceWorker.addEventListener('message', event => {
    const message = JSON.parse(event.data)
    const isStaleContentMessage = message.type === 'stale-content'

    if (isStaleContentMessage) {
      const staleContentIsNotOlderThan30Minutes = message.timeCached > new Date().getTime() - (1000 * 60 * 30)
      clearTimeout(timeout)

      if (shouldManualRefresh || staleContentIsNotOlderThan30Minutes) {
        showOffscreenMessage(staleContentNotificationElement)
        reloadButton.removeAttribute('aria-hidden')

        timeout = setTimeout(() => {
          hideOffscreenMessage(staleContentNotificationElement)
          reloadButton.setAttribute('aria-hidden', 'true')
        }, 20000)
      } else {
        window.location.reload()
      }
    }
  })
}

export default initStaleContentNotification
