import { renderToastMessage } from '../toast-message/toast-message'
import { emitEvent } from '../event-emitter/event-emitter'
import { trackEvent } from '../analytics/analytics'
import { getNormalizedPath } from '../../../../lib/get-normalized-path'

async function initBookmark() {
  const bookmarkTriggers = document.querySelectorAll('[data-bookmark]')

  if ('serviceWorker' in navigator) {
    const cache = await caches.open('bookmarks')

    Array.prototype.forEach.call(bookmarkTriggers, async bookmark => {
      const url = bookmark.getAttribute('data-bookmark-url')
      const normalizedPath = getNormalizedPath(new Request(url))

      bookmark.addEventListener('click', event => {
        event.preventDefault()
        handleBookmark(normalizedPath, cache, bookmark)
      })

      const isBookmarked = await cache.match(normalizedPath)
      isBookmarked ? bookmark.classList.add('bookmark--active') : bookmark.classList.remove('bookmark--active')
      bookmark.classList.add('bookmark--enhanced')
    })
  }
}

async function handleBookmark(url, cache, bookmarkElement) {
  const isBookmarked = await cache.match(url)
  const addSuccessMessage = bookmarkElement.getAttribute('data-bookmark-add-success-message')
  const addErrorMessage = bookmarkElement.getAttribute('data-bookmark-add-error-message')
  const removeSuccessMessage = bookmarkElement.getAttribute('data-bookmark-remove-success-message')
  const removeErrorMessage = bookmarkElement.getAttribute('data-bookmark-remove-error-message')

  if (!isBookmarked) {
    bookmarkElement.classList.add('bookmark--active')
    await fetch(url)
      .then(response => cache.put(url, response))
      .then(() => {
        renderToastMessage(addSuccessMessage)
        trackEvent('bookmark', 'add', url)
      })
      .catch(() => {
        bookmarkElement.classList.remove('bookmark--active')
        renderToastMessage(addErrorMessage)
      })
  } else {
    const isRemoved = await cache.delete(url)
    if (isRemoved) {
      bookmarkElement.classList.remove('bookmark--active')
      renderToastMessage(removeSuccessMessage)
      trackEvent('bookmark', 'remove', url)
      emitEvent({
        element: bookmarkElement,
        name: 'bookmark-removed',
      })
    } else (
      renderToastMessage(removeErrorMessage)
    )
  }

  const bookmarkCount = (await cache.keys()).length

  emitEvent({
    detail: { count: bookmarkCount },
    name: 'bookmarks-updated',
  })
}

export default initBookmark
