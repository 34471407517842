import cookie from '../../../../lib/cookie'

const promotedSlots = document.querySelectorAll('[data-promoted-slot]')

function initPromotedSlots() {
  if(!promotedSlots.length) {
    return
  }

  if (cookie('cookie-consent-seen')) {
    enablePromotions()
  } else {
    listenForConsent()
  }
}

function enablePromotions() {
  Array.prototype.forEach.call(promotedSlots, (slot) => {
    const id = slot.getAttribute('id')
    googletag.cmd.push(() => googletag.display(id)) // eslint-disable-line no-undef
  })
}

function listenForConsent() {
  window.addEventListener('cookie-consent-seen', () => {
    enablePromotions()
  })
}

export default initPromotedSlots
