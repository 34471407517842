import { emitEvent } from '../event-emitter/event-emitter'
import { enableFocusTrap, disableFocusTrap } from '../focus-trap/focus-trap'

const closeButtons = document.querySelectorAll('[data-modal-close]')
const openButtons = document.querySelectorAll('[data-modal-open]')
let scrollYOffset = 0
const MODAL_DIALOG_ENTER = 'modal__dialog--enter'
const MODAL_DIALOG_LEAVE = 'modal__dialog--leave'
const MODAL_BACKDROP_ENTER = 'modal__backdrop--enter'
const MODAL_BACKDROP_LEAVE = 'modal__backdrop--leave'

const openModal = (id) => {
  const modalDialog = document.querySelector(`[data-modal-dialog="${id}"]`)
  const modal = document.querySelector(`[data-modal="${id}"]`)
  const modalBackdrop = document.querySelector('[data-modal-backdrop]')
  modalDialog.classList.add(MODAL_DIALOG_ENTER)
  modalBackdrop.classList.add(MODAL_BACKDROP_ENTER)
  scrollYOffset = window.pageYOffset
  modal.classList.add('modal--open')
  modalDialog.setAttribute('open', '')
  document.documentElement.style.setProperty('--scroll-offset', scrollYOffset * -1 + 'px')
  document.body.classList.add('disable-scroll')
  document.documentElement.classList.add('disable-scroll')
  enableFocusTrap(modalDialog)

  function handleKeyDown(event) {
    if (event.key === 'Escape') {
      closeModal(id)
    }
  }

  modalDialog.addEventListener('keydown', (event) => handleKeyDown(event))
  modalDialog.removeEventListener('keydown', (event) => handleKeyDown(event))

  return new Promise((resolve) => {
    const onanimationend = () => {
      modalDialog.classList.remove(MODAL_DIALOG_ENTER)
      modalBackdrop.classList.remove(MODAL_BACKDROP_ENTER)
      modalDialog.removeEventListener('animationend', onanimationend)
      resolve()
    }
    modalDialog.addEventListener('animationend', onanimationend)
  })
}

const closeModal = (id) => {
  const modalDialog = document.querySelector(`[data-modal-dialog="${id}"]`)
  const modal = document.querySelector(`[data-modal="${id}"]`)
  const modalBackdrop = document.querySelector('[data-modal-backdrop]')
  modalDialog.classList.add(MODAL_DIALOG_LEAVE)
  modalBackdrop.classList.add(MODAL_BACKDROP_LEAVE)

  return new Promise((resolve) => {
    const onanimationend = () => {
      modal.classList.remove('modal--open')
      modalDialog.removeAttribute('open')
      emitEvent({ element: modalDialog, name: 'modal-closed' })
      document.documentElement.style.removeProperty('--scroll-offset')
      document.body.classList.remove('disable-scroll')
      document.documentElement.classList.remove('disable-scroll')
      document.documentElement.scrollTop = scrollYOffset
      scrollYOffset = 0
      disableFocusTrap(modalDialog)
      modalDialog.classList.remove(MODAL_DIALOG_LEAVE)
      modalBackdrop.classList.remove(MODAL_BACKDROP_LEAVE)
      modalDialog.removeEventListener('animationend', onanimationend)
      resolve()
    }

    modalDialog.addEventListener('animationend', onanimationend)
  })
}

const initModal = () => {
  if (!closeButtons.length) {
    return
  }

  Array.prototype.forEach.call(closeButtons, closeButton => {
    closeButton.addEventListener('click', () => {
      const modalId = closeButton.getAttribute('data-modal-close')
      closeModal(modalId)
    })
  })

  Array.prototype.forEach.call(openButtons, openButton => {
    openButton.addEventListener('click', () => {
      const modalId = openButton.getAttribute('data-modal-open')
      openModal(modalId)
    })
  })
}

export { openModal, closeModal, initModal }
