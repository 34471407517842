let container, checkbox, value

const state = {
  loading: false,
  error: false,
  valid: false
}

function initGeolocation() {
  container = document.querySelector('[data-geolocation]')

  if(!container) {
    return
  }

  if(!navigator.geolocation) {
    return
  }

  container.classList.remove('visually-hidden')
  checkbox = container.querySelector('[data-geolocation-checkbox]')
  value = container.querySelector('[data-geolocation-value]')

  if(checkbox) {
    checkbox.addEventListener( 'change', checkboxHandler)
  }
}

function checkboxHandler() {

  if(state.loading) {
    return
  }

  if(checkbox.checked) {
    setState('loading', true)
    navigator.geolocation.getCurrentPosition(successHandler, errorHandler)
  } else {
    value.value = ''
    setState('valid', false)
  }
}

function successHandler(position) {
  setState('loading', false)
  setState('valid', true)

  const { latitude, longitude } = position.coords
  value.value = `Lat: ${latitude}, Long: ${longitude}`
}

function errorHandler() {
  setState('loading', false)
  setState('error', true)
}

function setState(key, value) {
  container.setAttribute(`data-geolocation-${key}`, value)
  state[key] = value
}

export default initGeolocation
