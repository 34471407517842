import initBookmark from '../../_includes/components/bookmark/bookmark'
import { emitEvent } from '../../_includes/components/event-emitter/event-emitter'

const bookmarkListElement = document.querySelector('[data-bookmarks]')
const runtimeCachedListElement = document.querySelector('[data-bookmarks-runtime-cached]')
const contentBundleListElement = document.querySelector('[data-bookmarks-content-bundle]')

function initBookmarkList() {
  if (!bookmarkListElement && !runtimeCachedListElement) {
    return
  }

  if (!('serviceWorker' in navigator)) {
    document.querySelector('[data-bookmarks-no-support]').classList.add('is-visible')
    return
  }

  // render bookmarked pages
  getCachedDetailPages('bookmarks')
    .then(bookmarks => {
      if (!bookmarks) {
        return
      }
      bookmarkListElement.innerHTML = renderBookmarks(bookmarks)

      initBookmark()

      // listen for custom events from bookmark component
      Array.prototype.forEach.call(document.querySelectorAll('[data-bookmarks-list-item]'), (element) => {
        element.addEventListener('bookmark-removed', (e) => {
          e.currentTarget.remove()
          if(document.querySelectorAll('[data-bookmarks-list-item]').length === 0) {
            emitEvent({ element: document, name: 'all-bookmarks-removed' })
          }
        })
      })
    })

  // render runtime cached pages
  getCachedDetailPages('html-cache')
    .then(cachedUrls => {
      if (!cachedUrls) {
        return
      }
      runtimeCachedListElement.innerHTML = renderBookmarks(cachedUrls)
    })

  // render content bundle pages
  getCachedDetailPages('content-bundle')
    .then(cachedUrls => {
      if (!cachedUrls) {
        return
      }
      contentBundleListElement.innerHTML = renderBookmarks(cachedUrls)
    })
}

function getCachedDetailPages(cacheName) {
  return caches.open(cacheName)
    .then(cache => cache.keys())
    .then(async htmlRequests => {
      const requests = await Promise.all(htmlRequests.map(getPostMeta))
      return requests.filter(Boolean)
    })
}

function renderBookmarks(bookmarks) {
  return bookmarks
    .map(bookmark => `<li data-bookmarks-list-item>${bookmark.card}</li>`)
    .join('')
}

function getPostMeta(request) {
  return caches.match(request)
    .then(response => response ? response.text() : '')
    .then(htmlAsText => {
      const template = document.createElement('template')
      template.innerHTML = htmlAsText
      const pageMetaTemplate = template.content.querySelector('[data-post-meta]')

      if (pageMetaTemplate) {
        const pageMeta = pageMetaTemplate.content
        return JSON.parse(pageMeta.textContent)
      }
    })
}

export default initBookmarkList
