const iranAfterDark = document.querySelector('[data-iran-after-dark]')

function initIranAfterDark () {
  if (!iranAfterDark) {
    return
  }

  const infoMessage = document.querySelector('[data-iran-after-dark-info-message]')
  const statusMessage = document.querySelector('[data-iran-after-dark-status]')
  const redirectLink = document.querySelector('[data-iran-after-dark-redirect-link]')
  const unknownMessage = document.querySelector('[data-iran-after-dark-unknown-message]')
  const availableMessage = document.querySelector('[data-iran-after-dark-available-message]')
  const unAvailableMessage = document.querySelector('[data-iran-after-dark-unavailable-message]')

  // check if Iran After Dark is up by doing a no-cors request
  fetch('https://irandarkhamooshi.net/', { mode: 'no-cors' })
    .then(() => {
      unknownMessage.classList.add('is-hidden')
      statusMessage.setAttribute('data-iran-after-dark-status', 'available')
      availableMessage.classList.remove('is-hidden')
      redirectLink.classList.remove('is-hidden')
      window.open('https://irandarkhamooshi.net/', '_blank')
    })
    .catch(() => {
      unknownMessage.classList.add('is-hidden')
      statusMessage.setAttribute('data-iran-after-dark-status', 'unavailable')
      unAvailableMessage.classList.remove('is-hidden')
      infoMessage.classList.add('is-hidden')

      window.location.href = '/assets/iran-after-dark.html'
    })
}

export default initIranAfterDark
