function initSkipLink() {
  const skipLink = document.querySelector('[data-skip-link]')

  if (skipLink) {
    skipLink.addEventListener('click', (event) => {
      event.preventDefault()
      const target = skipLink.getAttribute('href').split('#')[1]

      window.location.hash = target
    })
  }
}

export default initSkipLink
