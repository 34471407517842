async function initBookmarkCount() {
  const bookmarkCounters = document.querySelectorAll('[data-bookmark-count]')

  if ('serviceWorker' in navigator) {
    const initialBookmarkCount = await caches
      .open('bookmarks')
      .then((cache) => cache.keys())
      .then((keys) => keys.length)

    bookmarkCounters.forEach((counter) => setCounterTextContent(counter, initialBookmarkCount))

    window.addEventListener('bookmarks-updated', (event) => {
      bookmarkCounters.forEach((counter) => setCounterTextContent(counter, event.detail.count))
    })
  }
}

function setCounterTextContent(counter, count) {
  if (count > 0) {
    counter.textContent = count > 99 ? '99+' : count
  } else {
    counter.textContent = ''
  }
}

export default initBookmarkCount
