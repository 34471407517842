import { openModal } from '../modal/modal'

const MODAL_ID = 'newsletter-subscribe-notification'
const SUBSCRIBE_REMINDER_LOCAL_STORAGE_KEY = 'newsletter-subscribe-last-reminder'
const SUBSCRIBE_REMINDER_EXPIRATION_DAYS = 28 // to test use -1 to show on every refresh
const intersectionObserverSupported = 'IntersectionObserver' in window
  && 'IntersectionObserverEntry' in window
  && 'isIntersecting' in window.IntersectionObserverEntry.prototype


const modal = document.querySelector(`[data-modal="${MODAL_ID}"]`)

const state = {
  isIntersected: false,
}

export default function initNewsletterSubscribeNotification() {
  const newsletterSubscribeTriggerElement = document.querySelector('[data-newsletter-subscribe-trigger]')

  if (
    !newsletterSubscribeTriggerElement ||
    !intersectionObserverSupported ||
    !modal ||
    lastReminderWasLessThanFourWeeksAgo()
  ) {
    return
  }

  triggerNewsletterSubscribeModal(newsletterSubscribeTriggerElement).then(() => {
    openModal(MODAL_ID).then(() => {
      localStorage.setItem(SUBSCRIBE_REMINDER_LOCAL_STORAGE_KEY, new Date())
      state.isIntersected = false
    })
  })
}

function triggerNewsletterSubscribeModal(element) {
  const options = {
    root: null,
  }

  return new Promise((resolve) => {
    const observer = new IntersectionObserver(handleIntersection, options)
    observer.observe(element)

    function handleIntersection(entries, observer) {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry

        if (isIntersecting) {
          state.isIntersected = true
        }

        if (!isIntersecting && state.isIntersected) {
          observer.unobserve(target)
          resolve()
        }
      })
    }
  })
}

function lastReminderWasLessThanFourWeeksAgo() {
  const lastReminder = localStorage.getItem(SUBSCRIBE_REMINDER_LOCAL_STORAGE_KEY)

  if (lastReminder === null) {
    return false
  }

  const today = new Date()
  const fourWeeksAgo = new Date()
  fourWeeksAgo.setDate(today.getDate() - SUBSCRIBE_REMINDER_EXPIRATION_DAYS)

  return fourWeeksAgo < new Date(lastReminder)
}
