import cookie from '../../../../lib/cookie'
const menus = document.querySelectorAll('[data-theme-menu]')

function initThemeMenu() {
  if(!menus) {
    return
  }

  const prefix = 'color-theme-'
  let theme = 'light'

  if(cookie(`${prefix}dark`)) {
    updateTheme('dark')
  }

  if(cookie(`${prefix}light`)) {
    updateTheme('light')
  }

  Array.prototype.forEach.call(menus, menu => {
    const buttons = menu.querySelectorAll('[data-theme-menu-toggle]')

    Array.prototype.forEach.call(buttons, button => {
      button.addEventListener('click', () => {
        updateTheme(button.dataset.themeMenuToggle)
      })
    })
  })

  function updateTheme(newTheme) {

    document.documentElement.classList.remove(`${prefix}${theme}`)
    cookie(`${prefix}${theme}`, false)

    document.documentElement.classList.add(`${prefix}${newTheme}`)
    cookie(`${prefix}${newTheme}`, true, 365)

    theme = newTheme
  }
}

export default initThemeMenu
