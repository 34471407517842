const reportForReviewComponents = document.querySelectorAll('[data-report-for-review]')

function initReportForReview() {
  if (!reportForReviewComponents.length) {
    return
  }

  const reportForReviewButtons = document.querySelectorAll('[data-report-for-review-button]')
  const notificationBody = {
    blocks: [
      {
        type: 'section',
        text: {
          type: 'mrkdwn',
          text: `Hey @channel :tada: we've just received a request for review of the following article: ${window.location.href}. On my way! :robot_face:`
        }
      },
    ]
  }

  Array.prototype.forEach.call(reportForReviewComponents, reportForReviewComponent => {
    const feedbackElement = reportForReviewComponent.querySelector('[data-form-feedback]')
    const reportForReviewSuccessMessage = reportForReviewComponent.getAttribute('data-report-for-review-success-message')
    const reportForReviewErrorMessage = reportForReviewComponent.getAttribute('data-report-for-review-error-message')
    const reportForReviewButton = reportForReviewComponent.querySelector('[data-report-for-review-button]')

    feedbackElement.setAttribute('data-form-feedback', '')
    feedbackElement.textContent = ''

    reportForReviewButton.addEventListener('click', () => {
      fetch('https://hooks.slack.com/services/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', {
        method: 'post',
        body: JSON.stringify(notificationBody)
      })
        .then((res) => {
          if (res.ok) {
            // disable all report buttons
            Array.prototype.forEach.call(reportForReviewButtons, button => {
              button.disabled = 'disabled'
            })

            // render feedback messsage
            feedbackElement.setAttribute('data-form-feedback', 'success')
            feedbackElement.textContent = reportForReviewSuccessMessage
          } else {
            throw 'Could not post to slack with error'
          }
        })
        .catch(() => {
          feedbackElement.setAttribute('data-form-feedback', 'error')
          feedbackElement.textContent = reportForReviewErrorMessage
        })
    })
  })
}

export default initReportForReview
